<template>
  <main 
    :class="classes"
    :style="style">
    <div 
      class="layout-survey__imgbanner" 
      v-if="banner">
      <img
        id="survey-banner"
        alt=""
        class="layout-survey__img"
        :src="banner.url"
      />
    </div>

    <!-- Loading -->
    <div 
      class="layout-survey__loader" 
      v-if="loading">
      <ui-loader />
    </div>

    <div class="layout-survey__container" v-else>
      <!-- Header -->
      <header class="survey__header" v-if="logged">
        <navigations-breadcrumb
          class="view-survey__breadcrumb"
          :children="breadcrumbs" 
        />
      </header>

      <div class="view-survey__header">
        <div class="view-survey__texts">
          <h2 class="survey__subtitle">{{ title }}</h2>
          <div class="survey__subdescription">{{ description }}</div>
        </div>
      </div>

      <form
        class="view-survey__body"
        v-if="!output"
        @submit="onSubmit">
        <div
          class="view-survey__question"
          :key="code"
          v-for="code in codes">
          <component
            :errors="getErrors(`answers.${code}`)"
            :data="getData(code)"
            :is="getComponent(code)"
            :readonly="submitted"
            v-model="values[code]"
          />
        </div>

        <div class="view-survey__submit">
          <actions-button
            v-if="!submitted"
            :appearance="$pepper.Appearance.PRIMARY"
            @click="submit"
          >{{ $t('user-portal.survey_submit') }}</actions-button>
        </div>
      </form>

      <div
        class="view-survey__body"
        v-if="output !== null">
        <p class="view-survey__output" v-html="output"></p>
        <form-user
          v-if="anonymous && !locked"
          :errors="errors"
          @submit="claim"
        />
      </div>
    </div>
  </main>
</template>

<script>
import MixinErrors from '@/helpers/errors'
import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'

import CommentInput from '../../components/comment'
import FormUser from '../../components/form-user'
import StarsInput from '../../components/stars'

export default {
  name: 'Survey',

  inject: [
    '$feedback',
    '$user'
  ],

  components: {
    FormUser,
    NavigationsBreadcrumb
  },
  
  mixins: [ 
    MixinErrors,
  ],

  data() {
    return {
      loading: false,
      locked: false,
      values: null,
      output: null,
      errors: {},
      bannerHeight: 0
    }
  },

  computed: {
    anonymous() {
      return this.$user.user.isAnonymous
    },

    banner() {
      return this.$basil.get(this.feedback, 'images', []).find(i => i.field_name === 'banner')
    },

    breadcrumbs() {
      return [
        { label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' } },
        { label: this.$t('user-portal.surveys_title'), href: { name: 'sayl-user-portal.surveys' } },
        { label: this.loading ? this.$t('user-portal.loading') : this.title, href: '#' }
      ]
    },

    classes() {
      return {
        'container': true,
        'layout-survey__body': true,
        '-is-loading': this.loading
      }
    },

    codes() {
      return Object.keys(this.values ?? {})
    },

    description() {
      return this.$feedback.feedback?.description ?? ''
    },

    feedback() {
      return this.$feedback.feedback
    },

    logged() {
      return this.$user.user.isLogged
    },

    style() {
      return `--banner-height: ${this.bannerHeight}px;`
    },

    submitted() {
      return this.$basil.get(this.feedback, 'entries[0].answers') !== undefined
    },

    title() {
      return this.$feedback.feedback?.title ?? ''
    }
  },

  methods: {
    async claim(user) {
      this.loading = true
      try {
        await this.$feedback.claimFreeSurvey({ surveyId: this.$route.params.surveyId, user })
        await this.$user.view()
      } catch (e) {
        this.handleErrors(e)
      } finally {
        this.loading = false
      }
    },

    getComponent(code) {
      let question = this.$basil.get(this.feedback, 'questions', []).find(question => question.code === code)

      if (this.$basil.isNil(question)) {
        return
      }

      if (question.type === 'stars') {
        return StarsInput;
      }

      if (question.type === 'user_input' && question.input === 'textarea') {
        return CommentInput;
      }
    },

    getData(code) {
      let question = this.$basil.get(this.feedback, 'questions', []).find(question => question.code === code)

      if (this.$basil.isNil(question)) {
        return
      }

      return question
    },

    onSubmit(e) {
      e.preventDefault()
    },

    async reset() {
      this.loading = true
      try {
        await this.$feedback.viewFreeSurvey({ surveyId: this.$route.params.surveyId })
        this.values = this.feedback.questions.reduce((carry, question) => {
          const answer = this.$basil.get(this.feedback, 'entries[0].answers', []).find((a => a.question_code === question.code))

          carry[question.code] = answer !== undefined ? answer.value : this.$basil.get(question, 'default', '')

          return carry
        }, {})
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
        setTimeout(() => this.resize(), 100)
      }
    },

    resize() {
      let el = document.getElementById('survey-banner')
      if(el && this.banner != null) {
        this.bannerHeight = el.getBoundingClientRect().height

        if (this.bannerHeight < 400) {
          this.bannerHeight = 2 * this.bannerHeight
        }

        if (this.bannerHeight == 0 && this.banner != null) {
          setTimeout(() => this.resize(), 100)
        }
      }
    },

    async submit() {
      this.loading = true
      try {
        const data = await this.$feedback.submitFreeSurvey({ surveyId: this.$route.params.surveyId, answers: this.values })
        this.output = data.output
      } catch (e) {
        if (e.status === 423) {
          this.locked = true
          this.output = this.$t('user-portal.survey_already_submitted')
        } else {
          this.handleErrors(e)
        }
      } finally {
        this.loading = false
      }
    }
  },

  mounted() {
    this.reset()
    window.addEventListener('resize', this.resize)
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>